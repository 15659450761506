document.addEventListener('DOMContentLoaded', (event) => {

  document.querySelectorAll('a[rel=external]').forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      window.open(link.getAttribute('href'));
    })
  })

})
